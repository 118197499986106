import { add, differenceInMinutes, intervalToDuration } from "date-fns";
export function getRecruitDeadline(targetDate) {
    const now = new Date();
    const diff = Math.round(differenceInMinutes(targetDate, now) / 20) * 10;
    if (diff < 30) return {
        minutes: 30
    };
    const deadline = add(now, {
        minutes: diff
    });
    return intervalToDuration({
        start: now,
        end: deadline
    });
}
